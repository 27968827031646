* {
    font-family: 'Noto Sans KR', sans-serif;
}

body {
    overflow: hidden;
}

.App {
    height: 100%;
}

